.auth-page {
  /* width: 100vw; */
  height: 100vh;
  display: flex;
  background-size: 100% 100%;
  align-items: center;
  justify-content: center;
}

.auth-bg {
  width: 100vw;
  height: 100vh;
}

.auth-form {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 350px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.auth-form > img {
  max-width: 70%;
  margin: 0 auto;
}

.auth-form > .auth-input {
  display: flex;
  flex-direction: column;
}

.auth-form > .auth-input > input {
  flex: 1;
  padding: 15px;
  border: 1px solid #ccc;
  font-size: 16px;
  font-weight: bold;
}

.auth-form > button {
  max-width: 120px;
  padding: 10px;
  background-color: #10365d;
  border-color: #10365d;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
}

.auth-form > button:active {
  background-color: #061a2f;
  border-color: #061a2f;
  color: #ccc;
}

.input-success {
  border-color: green !important;
}

.input-error {
  border-color: red !important;
}

.input-error-message {
  color: red;
}

.auth-text {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #3182ff;
  font-size: 23px;
  text-shadow: 0 0 2px #3182ff;
}
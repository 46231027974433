html,
body {
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
}

.contentpage {
  position: relative;
}

.contentpage > img {
  display: block;
}

.domitem {
  /* border: 1px solid red; */
  position: absolute;
  font-weight: bold;
}

.pagelayout {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.coloritem {
  border-radius: 50%;
  border: 1px solid #000;
}

.coloritem > img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.LevelItem {
  background-color: #fff;
}

.LevelItem > span {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: blue;
}

.layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  /* background: #fff url(/images/sbg2.jpg); */
  padding: 0px;
  margin: 0px;
  font-size: 16px;
  color: #454545;
  font-family: Arial, Helvetica, sans-serif;
}

html {
  overflow-y: scroll;
}

main {
  flex: 1;
  background-color: #fffffff2;
  padding: 100px 0;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  /* background: rgba(0, 0, 0, 0.5); */
}

.header-top {
  min-height: 100px;
  border-radius: 15px;
  background-color: #fff;
  padding: 15px;
  /* box-shadow: 1px 1px 10px #ccc; */
  margin-bottom: 0px;
  margin-top: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.titre_ville {
  text-align: center;
  flex: 1;
}

.titre_header,
.titre_ville {
  font-weight: bold;
  color: #138bd8;
  font-size: 20px;
}

.middleinfos {
  flex: 3;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer {
  height: 70px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}

.middleinfos-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.middleinfos-content img {
  height: 70px;
}

.top-menu {
  height: 50px;
  background-color: #138bd8;
  display: flex;
  align-items: center;
  justify-content: center;
}

.top-menu ul {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  list-style: none;
}

.top-menu ul li {
  color: #fff;
  height: 50px;
  min-width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

li:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

li.active {
  background-color: rgba(0, 0, 0, 0.5);
}

.top-menu ul li a {
  color: #fff;
  text-decoration: none;
}

.container {
  width: 80%;
  max-width: 1218px;
}

.top-menu > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.led {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.led-T {
  background-color: green;
}

.led-F {
  background-color: red;
}

.contentpage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

table.blueTable {
  border: 1px solid #1c6ea4;
  background-color: #eeeeee;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}
table.blueTable td,
table.blueTable th {
  border: 1px solid #aaaaaa;
  padding: 3px 2px;
}
table.blueTable tbody td {
  font-size: 13px;
}
table.blueTable tr:nth-child(even) {
  background: #d0e4f5;
}
table.blueTable thead {
  background: #1c6ea4;
  background: -moz-linear-gradient(top, #5592bb 0%, #327cad 66%, #1c6ea4 100%);
  background: -webkit-linear-gradient(
    top,
    #5592bb 0%,
    #327cad 66%,
    #1c6ea4 100%
  );
  background: linear-gradient(to bottom, #5592bb 0%, #327cad 66%, #1c6ea4 100%);
  border-bottom: 2px solid #444444;
}
table.blueTable thead th {
  font-size: 15px;
  font-weight: bold;
  color: #ffffff;
  border-left: 2px solid #d0e4f5;
}
table.blueTable thead th:first-child {
  border-left: none;
}

table.blueTable tfoot {
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  background: #d0e4f5;
  background: -moz-linear-gradient(top, #dcebf7 0%, #d4e6f6 66%, #d0e4f5 100%);
  background: -webkit-linear-gradient(
    top,
    #dcebf7 0%,
    #d4e6f6 66%,
    #d0e4f5 100%
  );
  background: linear-gradient(to bottom, #dcebf7 0%, #d4e6f6 66%, #d0e4f5 100%);
  border-top: 2px solid #444444;
}
table.blueTable tfoot td {
  font-size: 14px;
}
table.blueTable tfoot .links {
  text-align: right;
}
table.blueTable tfoot .links a {
  display: inline-block;
  background: #1c6ea4;
  color: #ffffff;
  padding: 2px 8px;
  border-radius: 5px;
}

.myButton {
  box-shadow: inset 0px 1px 0px 0px #97c4fe;
  background: linear-gradient(to bottom, #3d94f6 5%, #1e62d0 100%);
  background-color: #3d94f6;
  border-radius: 6px;
  border: 1px solid #337fed;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-family: Arial;
  font-size: 15px;
  font-weight: bold;
  padding: 6px 24px;
  text-decoration: none;
  text-shadow: 0px 1px 0px #1570cd;
}
.myButton:hover {
  background: linear-gradient(to bottom, #1e62d0 5%, #3d94f6 100%);
  background-color: #1e62d0;
}
.myButton:active {
  position: relative;
  top: 1px;
}

.user-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.btn1 {
  box-shadow: inset 0px 1px 0px 0px #ffffff;
  background: linear-gradient(to bottom, #ffffff 5%, #f6f6f6 100%);
  background-color: #ffffff;
  border-radius: 6px;
  border: 1px solid #dcdcdc;
  display: inline-block;
  cursor: pointer;
  color: #666666;
  font-family: Arial;
  font-size: 9px;
  padding: 4px 7px;
  text-decoration: none;
  text-shadow: 0px 1px 0px #ffffff;
}
.btn1:hover {
  background: linear-gradient(to bottom, #f6f6f6 5%, #ffffff 100%);
  background-color: #f6f6f6;
}
.btn1:active {
  position: relative;
  top: 1px;
}

.btnlist {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
}

.actions {
  display: flex;
  gap: 10px;
}

.annuler {
  box-shadow: inset 0px 1px 0px 0px #ffffff;
  background: linear-gradient(to bottom, #f9f9f9 5%, #e9e9e9 100%);
  background-color: #f9f9f9;
  border-radius: 6px;
  border: 1px solid #dcdcdc;
  display: inline-block;
  cursor: pointer;
  color: #666666;
  font-family: Arial;
  font-size: 13px;
  padding: 7px 14px;
  text-decoration: none;
  text-shadow: 0px 1px 0px #ffffff;
}
.annuler:hover {
  background: linear-gradient(to bottom, #e9e9e9 5%, #f9f9f9 100%);
  background-color: #e9e9e9;
}
.annuler:active {
  position: relative;
  top: 1px;
}

.css-input {
  padding: 6px;
  font-size: 14px;
  border-width: 1px;
  border-color: #cccccc;
  background-color: #ffffff;
  color: #000000;
  border-style: solid;
  border-radius: 4px;
  box-shadow: 0px 0px 1px rgba(66, 66, 66, 0.54);
  text-shadow: 0px 0px 1px rgba(66, 66, 66, 0.75);
}
.css-input:focus {
  outline: none;
}

.formlayout {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.inputfield {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.action-eq > * {
  flex: 1;
}

.alert-success {
  color: green !important;
  font-weight: bold;
  padding: 15px;
}


.btnacc {
	box-shadow:inset 0px 1px 0px 0px #ffffff;
	background:linear-gradient(to bottom, #ededed 5%, #dfdfdf 100%);
	background-color:#ededed;
	border-radius:6px;
	border:1px solid #dcdcdc;
	display:inline-block;
	cursor:pointer;
	color:#777777;
	font-family:Arial;
	font-size:19px;
	font-weight:bold;
	padding:17px 36px;
	text-decoration:none;
	text-shadow:0px 1px 0px #ffffff;
}
.btnacc:hover {
	background:linear-gradient(to bottom, #dfdfdf 5%, #ededed 100%);
	background-color:#dfdfdf;
}
.btnacc:active {
	position:relative;
	top:1px;
}

.listbtn {
  display: flex;
  gap: 20px;
}

.titlepage {
	box-shadow:inset 12px 1px 5px -41px #ffffff;
	background:linear-gradient(to bottom, #ffffff 5%, #f6f6f6 100%);
	background-color:#ffffff;
	border-radius:6px;
	border:1px solid #dcdcdc;
	display:inline-block;
	cursor:pointer;
	color:#666666;
	font-family:Arial;
	font-size:26px;
	font-weight:bold;
	padding:22px 46px;
	text-decoration:none;
	text-shadow:0px 1px 0px #ffffff;
}
.titlepage:hover {
	background:linear-gradient(to bottom, #f6f6f6 5%, #ffffff 100%);
	background-color:#f6f6f6;
}
.titlepage:active {
	position:relative;
	top:1px;
}

.cursor{
  cursor: pointer;
}

.frm-curbe {
  display: flex;
  gap: 15px;
  align-items: flex-end;
}

.frm-input {
  display: flex;
  flex-direction: column;
}

.table-gabes td {
  text-align: center;
}